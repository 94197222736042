import React, { useState } from "react";
import Modal from "react-modal";

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [receivedOtp, setReceivedOtp] = useState("");
  const [inputOtp, setInputOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [step, setStep] = useState(1); // Step 1: Email input, Step 2: OTP input, Step 3: New password input

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInputOtpChange = (e) => {
    setInputOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    setError("");
    try {
      const response = await fetch("https://auth.clauseai.in/forgot-password", { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to send password reset email");
      }

      const responseData = await response.json();
      setReceivedOtp(responseData.otp);
      setStep(2);
    } catch (error) {
      console.error("Forgot password submission error:", error);
      setError("Failed to send password reset email");
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (inputOtp.length !== 6) {
      setError("OTP must be 6 digits");
      return;
    }
    if (inputOtp !== receivedOtp) {
      setError("Invalid OTP");
      return;
    }
    setError("");
    setStep(3);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setError("New password is required");
      return;
    }
    setError("");
    try {
      const response = await fetch("https://auth.clauseai.in/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, newPassword }),
      });

      if (!response.ok) {
        throw new Error("Failed to reset password");
      }

      // Display success message
      setSuccessMessage("Password reset successfully");

      // Reset form after 1.5 seconds and redirect to login page
      setTimeout(() => {
        setSuccessMessage("");
        onClose(); // Close modal
      }, 1500);
    } catch (error) {
      console.error("Password reset submission error:", error);
      setError("Failed to reset password");
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal" overlayClassName="modal-overlay">
      <h2>Forgot Password</h2>
      {step === 1 && (
        <form onSubmit={handleEmailSubmit}>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={email} onChange={handleEmailChange} required />
          {error && <p className="error">{error}</p>}
          <div className="button-group">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleOtpSubmit}>
          <label htmlFor="otp">OTP:</label>
          <div className="otp-input">
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                type="text"
                className="otp-box"
                value={inputOtp[index] || ""}
                onChange={(e) => {
                  let newOtp = inputOtp.split("");
                  newOtp[index] = e.target.value;
                  setInputOtp(newOtp.join(""));
                }}
                maxLength="1"
                required
              />
            ))}
          </div>
          {error && <p className="error">{error}</p>}
          <div className="button-group">
            <button type="submit">Verify OTP</button>
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      )}
      {step === 3 && (
        <form onSubmit={handleNewPasswordSubmit}>
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={handleNewPasswordChange}
            required
          />
          {error && <p className="error">{error}</p>}
          <div className="button-group">
            <button type="submit">Reset Password</button>
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      )}
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
    </Modal>
  );
};

export default ForgotPasswordModal;
