import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import { getSampleClauses } from "../../utils/GPTFunctions";
import SampleClauseCard from "./SampleClauseCard";
import ShimmerCard from "./ShimmerCards";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "90%", // Adjust as needed
    maxHeight: "90vh", // make it scrollable
    width: "90%", // make it big
    padding: "20px",
    backgroundColor: "#ebf8ff",
    opacity: 0, // Start with opacity 0 for animation
    transition: "opacity 0.5s ease", // Transition for opacity
    overflow: "auto",
  },
};

const MoreClausesModal = ({ isOpen, onRequestClose, title, content }) => {
  const [sampleClauses, setSampleClauses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false); // Visibility state for animation

  const handleCloseBtn = () => {
    setSampleClauses([]);
    onRequestClose();
  };

  useEffect(() => {
    const fetchSampleClauses = async () => {
      setLoading(true);
      try {
        const data = await getSampleClauses({ title, content });
        setSampleClauses(data);
      } catch (error) {
        console.error("Error fetching sample clauses:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchSampleClauses();
      setTimeout(() => {
        setIsVisible(true);
      }, 100); // Delay before showing modal content
    } else {
      setIsVisible(false);
    }
  }, [isOpen, title, content]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Sample Clauses Modal"
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          opacity: isVisible ? 1 : 0, // Change opacity based on visibility state
        },
      }}
    >
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          {loading ? "Generating Sample Clauses..." : "Generated Sample Clauses"}
        </h2>
        <button onClick={handleCloseBtn} className="border-none bg-none cursor-pointer text-2xl font-bold">
          &times;
        </button>
      </div>
      <div className="cards-container w-full mt-2 mb-2 flex flex-col gap-3">
        {loading ? (
          <>
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
          </>
        ) : (
          sampleClauses.map((clause, index) => <SampleClauseCard title={title} content={clause.desc} key={index} />)
        )}
      </div>
    </Modal>
  );
};

export default MoreClausesModal;
