import React from "react";

const WelcomeHeader = () => {
  return (
    <h1 className="w-[80%] bg-blue-700 text-center text-white text-2xl mx-auto rounded-full px-2 py-3">
      Welcome to Clause Ai
    </h1>
  );
};

export default WelcomeHeader;
