/* global Word console */

const insertText = async (text) => {
  // Write text to the document.
  try {
    await Word.run(async (context) => {
      let body = context.document.body;
      body.insertText(text, Word.InsertLocation.end);
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};

export const getDocument = async () => {
  try {
    return await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};

export const replaceSelectedText = async (replacementText) => {
  try {
    await Word.run(async (context) => {
      const selection = context.document.getSelection();
      selection.load("text");
      await context.sync();

      if (selection.text) {
        selection.insertText(replacementText, Word.InsertLocation.replace);
        await context.sync();
      } else {
        console.log("No text selected");
      }
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};


export const getSelectedText = async () => {
  try {
    return await Word.run(async (context) => {
      const selection = context.document.getSelection();
      selection.load("text");
      await context.sync();
      return selection.text;
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};

export default insertText;
