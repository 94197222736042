import React from "react";
import { Link } from "react-router-dom";

const LoginButton = ({ clickFunction }) => {
  const loginClick = () => {
    let btn = document.getElementById("loginLink");
    btn.click();
  };
  return (
    <>
      <button onClick={loginClick} className="text-white font-semibold bg-blue-700 rounded-full py-3">
        Login
      </button>
      <Link to="/login" id="loginLink" className="hidden">
        Login
      </Link>
    </>
  );
};

export default LoginButton;
