import React, { useState, useEffect } from "react";
import ClauseAiHeader from "./Mini Components/ClauseAiHeader";
import GenerateClauseButton from "./Mini Components/GenerateClauseButton";
import ClauseCard from "./Mini Components/ClauseCard";
import ShimmerCard from "./Mini Components/ShimmerCards";
import { API_KEY, ASSISTANT_ID, typeOfClauses, typeOfContracts } from "../utils/constants";
import { getDocument, getSelectedText } from "../office-document";
import { convertArrayToString, getClausesByForm, GPTparseStringToObjectsWithRating } from "../utils/GPTFunctions";
import { PropagateLoader } from "react-spinners";
import { OpenAI } from "openai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

const ClauseGenerator = () => {
  const askGptAssistant = async (clause) => {
    setAnalysedClause([]);
    setLoading(true);
    const assistantId = ASSISTANT_ID;
    const openai = new OpenAI({
      apiKey: API_KEY,
      dangerouslyAllowBrowser: true,
    });
  
    try {
      const thread = await openai.beta.threads.create();
      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: `Analyse the given clause ${clause} and rate this clause  from Low Risk,Medium Risk and High Risk give that in <h2> and  give some suggestion clauses from highly recommadation, recommaded to least recommandation 
         (Strict Instruction : just give the clauses no explainations,give the recommendation title in <h1> tag and thier descriptions in <p>  tags only not in any other form make sure they are seprated by '\n \n')
    (Strict insturuction : don't mention the category in brackets in answers)`,
      });
  
      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistantId,
        instructions:
          "keep my instructions strictly and Please address the user as your client and always give response as a new chat from zero knowledge about previous asked prompt",
      });
  
      const checkStatusAndPrint = async (threadId, runId) => {
        const runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);
        if (runStatus.status === "completed") {
          const msgs = await openai.beta.threads.messages.list(threadId);
          const msgArray = [msgs.data[0].content[0].text.value];
          const res = convertArrayToString(msgArray);
          return res;
        }
        return null;
      };
  
      let result = null;
      while (!result) {
        result = await checkStatusAndPrint(thread.id, run.id);
        if (!result) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }
  
      const data = GPTparseStringToObjectsWithRating(result);
      setAnalysedClause(data);
    } catch (error) {
      console.error("Error in askGptAssistant:", error);
    } finally {
      setLoading(false);
    }
  };

  const [selectedText, setSelectedText] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [formData, setFormData] = useState({
    typeOfContract: "default",
    customContract: "",
    clause: "",
    specificLaw: "",
    specificJurisdiction: "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [clauses, setClauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analysedClause, setAnalysedClause] = useState([]);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "typeOfContract" && value !== "others") {
      setFormData((prevData) => ({
        ...prevData,
        customContract: "",
      }));
    }
  };

  const handleClauseChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "clause" && value !== "others") {
      setFormData((prevData) => ({
        ...prevData,
        customClause: "",
      }));
    }
  };

  const refreshComponent = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleCustomContractChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      customContract: value,
    }));
  };

  const handleCustomClauseChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      customClause: value,
    }));
  };
  const openAccordion = async (e) => {
    e.preventDefault();
    if (analysedClause.length > 0) {
      setAnalysedClause([]);
      setClauses([]);
    }
    document.getElementById("clauseGeneratorForm").style.display = "none";
    setIsAccordionOpen(!isAccordionOpen);
    const text = await getSelectedText();
    if (text.length === 0) {
      console.log("text is: ", text);
      setSelectedText("No text selected. Please select a clause to analyze.");
    } else {
      try {
        setSelectedText(text);
        let res = await askGptAssistant(text);
      } catch (error) {
        console.error("Error occurred while asking GPT Assistant:", error);
        // Handle the error here
      }
    }
  };

  const closeAccordian = () => {
    setIsAccordionOpen(false);
    setAnalysedClause([]);
    setClauses([]); 
    document.getElementById("clauseGeneratorForm").style.display = "flex";
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.typeOfContract === "default") {
      newErrors.typeOfContract = "Please select a type of contract.";
    }
    if (!formData.clause) {
      newErrors.clause = "Please select a clause.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted");
      getClausesByForm(formData).then((response) => {
        console.log(response);
      });
      setSubmitted(true);
      generateClauses();
    } else {
      setSubmitted(false);
    }
  };

  const generateClauses = async () => {
    setLoading(true);
    try {
      const fetchedClauses = await getClausesByForm(formData);
      setClauses(fetchedClauses);
      setAnalysedClause([]);
    } catch (error) {
      console.error("Error generating clauses:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTextColorClass = (title) => {
    if (title.includes("High Risk")) {
      return "text-red-500";
    } else if (title.includes("Medium Risk")) {
      return "text-yellow-500";
    } else if (title.includes("Low Risk")) {
      return "text-green-500";
    } else {
      return "text-black";
    }
  };

  return (
    <div className="font-sans w-full h-[100vh] bg-blue-100 flex flex-col items-center transition">
      <ClauseAiHeader />
      <form
        id="clauseGeneratorForm"
        onSubmit={handleSubmit}
        className="form-container mb-4 mt-4 flex flex-col w-full justify-between items-center transition"
      >
        <div className="row1 w-11/12 mx-auto flex flex-row justify-between">
          <div className="col flex flex-col justify-start items-start w-[48%]">
            <label htmlFor="typeOfContract" className="pl-[6%] font-medium">
              Type of Agreement
            </label>
            <select
              name="typeOfContract"
              id="typeOfContract"
              value={formData.typeOfContract}
              onChange={handleChange}
              className="rounded-3xl px-3 py-2 w-full border border-gray-300"
            >
              <option value="default">Select an Agreement</option>
              {typeOfContracts.map((contract, index) => (
                <option key={index} value={contract}>
                  {contract}
                </option>
              ))}
              <option value="others">Others</option>
            </select>
            {formData.typeOfContract === "others" && (
              <input
                type="text"
                name="customContract"
                value={formData.customContract}
                onChange={handleCustomContractChange}
                placeholder="Enter type of contract"
                className="rounded-3xl px-3 py-2 w-full border border-gray-300 mt-2"
              />
            )}
            {errors.typeOfContract && <span className="text-red-500">{errors.typeOfContract}</span>}
          </div>
          <div className="col flex flex-col justify-start items-start w-[48%]">
            <label htmlFor="clause" className="pl-[6%] font-medium">
              Select Clause
            </label>
            <select
              name="clause"
              id="clause"
              value={formData.clause}
              onChange={handleClauseChange}
              className="rounded-3xl px-3 py-2 w-full border border-gray-300"
            >
              <option value="">Select a Clause</option>
              {typeOfClauses.map((clause, index) => (
                <option key={index} value={clause}>
                  {clause}
                </option>
              ))}
              <option value="others">Others</option>
            </select>
            {formData.clause === "others" && (
              <input
                type="text"
                name="customClause"
                value={formData.customClause}
                onChange={handleCustomClauseChange}
                placeholder="Enter custom clause"
                className="rounded-3xl px-3 py-2 w-full border border-gray-300 mt-2"
              />
            )}
            {errors.clause && <span className="text-red-500">{errors.clause}</span>}
          </div>
        </div>
        <div className="row2 w-11/12 mx-auto flex flex-row justify-between mt-4">
          <div className="col flex flex-col justify-start items-start w-[48%]">
            <label htmlFor="specificLaw" className="pl-[6%] font-medium">
              Any Specific Law (Optional)
            </label>
            <input
              type="text"
              id="specificLaw"
              name="specificLaw"
              value={formData.specificLaw}
              onChange={handleChange}
              placeholder="Goods and Service Taxes"
              className="rounded-3xl px-3 py-2 w-full border border-gray-300"
            />
          </div>
          <div className="col flex flex-col justify-start items-start w-[48%]">
            <label htmlFor="specificJurisdiction" className="pl-[6%] font-medium">
              Any Specific Jurisdiction (Optional)
            </label>
            <input
              type="text"
              id="specificJurisdiction"
              name="specificJurisdiction"
              value={formData.specificJurisdiction}
              onChange={handleChange}
              placeholder="New York"
              className="rounded-3xl px-3 py-2 w-full border border-gray-300"
            />
            {errors.specificJurisdiction && <span className="text-red-500">{errors.specificJurisdiction}</span>}
          </div>
        </div>
        <GenerateClauseButton />
        <p className="mt-3">OR</p>
        <button
          className="rounded-xl py-2 mt-3 bg-blue-700 text-white w-[88%] text-center text-base mx-auto mb-4"
          onClick={(e) => openAccordion(e)}
        >
          Clause Analyzer
        </button>
        <div className="flex items-center mt-4 justify-end flew-row w-[88%]">
          <button
            className="flex items-center text-blue-700 flex-col gap-1"
            onClick={(e) => {
              refreshComponent(e);
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            Refresh
          </button>
        </div>

        {submitted && loading && (
          <div className="clauses-container w-full mt-6 mb-6 flex flex-col gap-4 justify-center items-center transition">
            <p className="w-full mx-auto text-center flex justify-center items-center my-4">
              <PropagateLoader color="#1976d2" />
            </p>
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
            <ShimmerCard />
          </div>
        )}
        {submitted && !loading && clauses.length === 0 && <p className="mt-4 text-red-500">No clauses generated.</p>}
        {clauses.length > 0 && (
          <div className="clauses-container w-full mt-6 mb-6 flex flex-col gap-4">
            {clauses.map((clause, index) => (
              <ClauseCard key={index} title={clause.title} content={clause.desc} />
            ))}
          </div>
        )}
      </form>

      {isAccordionOpen && (
        <div
          className="accordion-content bg-gray-100 p-4 mt-2 rounded-md shadow-md overflow-auto transition"
          style={{ overflow: "auto" }}
        >
          <div className="flex justify-end items-center">
            <button onClick={closeAccordian} className="border-none bg-none cursor-pointer text-2xl font-bold">
              &times;
            </button>
          </div>
          <div className="flex flex-col gap-1 p-2">
            <span className="text-xl text-center font-semibold">Selected Clause</span>
            <p className="text-lg text-medium p-4 border-2 bg-white rounded-2xl shadow-lg">{selectedText}</p>
          </div>
          <div className="clauses-container w-full mt-6 mb-6 flex flex-col gap-4 overflow-auto items-center justify-center">
            {analysedClause.length > 0 &&
              analysedClause.map((clause, index) =>
                index === 0 ? (
                  <h1 key={index}>
                    <span className="text-xl font-bold px-4">Current Clause Risk: </span>
                    <span className={`text-xl font-semibold ${getTextColorClass(clause.rating)}`}>{clause.rating}</span>
                  </h1>
                ) : (
                  <ClauseCard title={clause.title} content={clause.desc} key={index} />
                )
              )}

            {loading && (
              <div className="clauses-container w-full flex flex-col gap-4 justify-center items-center transition">
                <p className="w-full mx-auto text-center flex justify-center items-center my-4">
                  <PropagateLoader color="#1976d2" />
                </p>
                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
                
              </div>
            )}
            {!loading && analysedClause.length === 0 && (
              <div>
                <p className="mt-4 text-red-500">No clauses generated.</p>
                <button
                  className="rounded-xl py-2 mt-3 bg-blue-700 text-white w-[88%] text-center text-base mx-auto mb-4"
                  onClick={closeAccordian}
                >
                  Select Clause
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClauseGenerator;
