import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faPlus } from "@fortawesome/free-solid-svg-icons";
import dotIcon from "../../../../assets/dotIcon.png";
import CustomModal from "./Modal";
import MoreClausesModal from "./MoreClausesModal";
import insertText from "../../office-document";

const ClauseCard = ({ title, content }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sampleModalIsOpen, setSampleModalIsOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    insertText(content);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openSampleModal = (event) => {
    event.preventDefault();
    setSampleModalIsOpen(true);
  };

  const closeSampleModal = () => {
    setSampleModalIsOpen(false);
  };

  function removeTrailingS(str) {
    if (str.endsWith("s")) {
      return str.slice(0, -1);
    }
    return str;
  }

  function removeStringAfterClause(inputString) {
    return inputString.replace(/Clause.*/, "Clause");
  }

  const getTextColorClass = (title) => {
    if (title.includes("Highly Recommended Clause")) {
      return "text-green-500";
    } else if (title.includes("Least Recommended Clause")) {
      return "text-red-500";
    } else if (title.includes("Recommended Clause")) {
      return "text-yellow-500";
    } else {
      return "text-black";
    }
  };

  return (
    <div className="clause-card flex flex-row justify-between h-[11rem] bg-white w-[92%] mx-auto rounded-2xl p-4 gap-6">
      <div className="body flex flex-col gap-2">
        <div className={`card-head text-xl font-semibold ${getTextColorClass(title)}`}>
          {removeStringAfterClause(removeTrailingS(title))}
        </div>
        <span className="text-base overflow-hidden">{content}</span>
      </div>
      <div className="btns-container w-[20%] py-4 flex flex-col justify-between items-center">
        <button className="p-1 rounded-lg border-2 border-black" onClick={(e) => handleClick(e)}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button className="p-1 rounded-lg border-2 border-black" onClick={openSampleModal}>
          <FontAwesomeIcon icon={faCommentDots} />
        </button>
        <img
          src={dotIcon}
          alt="dotIcon"
          height={"18px"}
          width={"18px"}
          onClick={openModal}
          className="cursor-pointer"
        />
      </div>
      <CustomModal title={title} content={content} isOpen={modalIsOpen} closeModal={closeModal} />

      <MoreClausesModal isOpen={sampleModalIsOpen} onRequestClose={closeSampleModal} title={title} content={content} />
    </div>
  );
};

export default ClauseCard;
