import React, { useEffect, useState } from 'react';


const DigitalClock = ({ expiryDate }) => {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const timeDiff = new Date(expiryDate) - now;
            if (timeDiff <= 0) {
                setTimeLeft("00:00");
                clearInterval(interval);
            } else {
                const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                setTimeLeft(`${hours} hr : ${minutes < 10 ? '0' : ''}${minutes} mins`);
            }
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [expiryDate]);

    return (
        <div className="text-2xl font-mono text-white p-2 rounded shadow-lg">
            <div className="flex items-center justify-center">
                <div className="text-4xl font-bold mr-2">{timeLeft}</div>
            </div>
        </div>
    );
};

export default DigitalClock;
