import React, { useState } from "react";
import WelcomeHeader from "./Mini Components/WelcomeHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/userSlice.js.js";

const SignupPage = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [formData, setFormData] = useState({
    emailId: "",
    username: "",
    password: "",
    lawFirmName: "",
    lawFirmSize: "",
    countryCode: "",
    phoneNumber: "",
    country: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [postError, setPostError] = useState(null); // State variable for backend API errors
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    let valid = true;

    // Email validation
    if (!formData.emailId.trim()) {
      errors.emailId = "Email ID is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.emailId)) {
      errors.emailId = "Email ID is invalid";
      valid = false;
    }

    // Username validation
    if (!formData.username.trim()) {
      errors.username = "Username is required";
      valid = false;
    }

    // Password validation
    if (!formData.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    } else if (formData.password.trim().length < 8) {
      errors.password = "Password must be at least 8 characters long";
      valid = false;
    }

    // Law Firm Name validation
    if (!formData.lawFirmName.trim()) {
      errors.lawFirmName = "Law Firm Name is required";
      valid = false;
    }

    // Law Firm Size validation
    const allowedSizes = ["1-10", "11-50", "51-100", "101-500", "501-1000", "1000+"];

    if (!formData.lawFirmSize.trim()) {
      errors.lawFirmSize = "Law Firm Size is required";
      valid = false;
    } else if (!allowedSizes.includes(formData.lawFirmSize)) {
      errors.lawFirmSize = "Law Firm Size must be one of the specified ranges";
      valid = false;
    }
    // Country Code validation
    if (!formData.countryCode.trim()) {
      errors.countryCode = "Country Code is required";
      valid = false;
    }
    // Phone Number validation
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
      valid = false;
    } else if (!/^\+?[0-9]{7,15}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone Number is invalid";
      valid = false;
    }

    // Country validation
    if (!formData.country.trim()) {
      errors.country = "Country is required";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        const response = await fetch("https://auth.clauseai.in/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const responseData = await response.json();

        if (!response.ok) {
          // If response is not ok, throw an error with the error message
          throw new Error(responseData.error || "Registration failed");
        }
        const userData = responseData.user;
        // Dispatch addUser action to update Redux store
        dispatch(addUser(userData));
        setSuccessMsg("User Registered Successfully !");
        setTimeout(() => {
          navigateTo("/generate_clause");
          setSuccessMsg("");
        }, 1500);

        // Optionally handle response data or redirect after successful registration
      } catch (error) {
        console.error("Error registering user:", error.message);
        setPostError(error.message);
        if (error.message === "Failed to fetch") {
          setPostError("Failed to connect to the server. Please try again later.");
        } else {
          const errorMessage = error.response.data.error;
          if (errorMessage.username) {
            setFormErrors({ ...formErrors, username: errorMessage.username });
          }
          if (errorMessage.emailId) {
            setFormErrors({ ...formErrors, emailId: errorMessage.emailId });
          }
          if (errorMessage.password) {
            setFormErrors({ ...formErrors, password: errorMessage.password });
          }
          if (errorMessage.lawFirmName) {
            setFormErrors({ ...formErrors, lawFirmName: errorMessage.lawFirmName });
          }
          if (errorMessage.lawFirmSize) {
            setFormErrors({ ...formErrors, lawFirmSize: errorMessage.lawFirmSize });
          }
          if (errorMessage.phoneNumber) {
            setFormErrors({ ...formErrors, phoneNumber: errorMessage.phoneNumber });
          }
          if (errorMessage.country) {
            setFormErrors({ ...formErrors, country: errorMessage.country });
          }
        }
      }
    }
  };

  return (
    <div className="w-[100vw] h-[100vh] bg-blue-100 flex flex-col justify-evenly items-center">
      <div className="head w-[100%]">
        <WelcomeHeader />
      </div>
      <div className="login-box flex flex-col w-[100%] mx-auto text-xl">
        <h1 className="text-center font-semibold">Sign Up</h1>
      </div>
      <div className="form-container w-[100%]">
        <form onSubmit={handleSubmit} className="flex flex-col justify-between">
          {successMsg.length > 0 && (
            <p className="text-green-500 rounded-full py-2 pl-3 text-xl font-bold text-center">{successMsg}</p>
          )}
          <p className="text-red-500 rounded-full py-2 pl-3 text-xl font-semibold text-center">{postError}</p>
          {/* First row */}
          <div className="row flex justify-center gap-[5%] items-center">
            <div className="box flex flex-col">
              <label htmlFor="emailId" className="rounded-full py-2 pl-3 text-lg">
                Email ID
              </label>
              <input
                placeholder="Email ID"
                type="text"
                name="emailId"
                id="emailId"
                value={formData.emailId}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              />
              {formErrors.emailId && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.emailId}</p>
              )}
            </div>
            <div className="box flex flex-col">
              <label htmlFor="username" className="rounded-full py-2 pl-3 text-lg">
                Username
              </label>
              <input
                placeholder="Username"
                type="text"
                name="username"
                id="username"
                value={formData.username}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              />
              {formErrors.username && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.username}</p>
              )}
            </div>
          </div>

          {/* Second row */}
          <div className="row flex justify-center gap-[5%] items-center">
            <div className="box flex flex-col">
              <label htmlFor="password" className="rounded-full py-2 pl-3 text-lg">
                Password
              </label>
              <input
                placeholder="Password"
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              />
              {formErrors.password && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.password}</p>
              )}
            </div>
            <div className="box flex flex-col">
              <label htmlFor="country" className="rounded-full py-2 pl-3 text-lg">
                Country
              </label>
              <input
                placeholder="Country"
                type="text"
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              />
              {formErrors.country && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.country}</p>
              )}
            </div>
          </div>

          {/* Third row */}
          <div className="row flex justify-center gap-[5%] items-center">
            <div className="box flex flex-col">
              <label htmlFor="lawFirmSize" className="rounded-full py-2 pl-3 text-lg">
                Law Firm Size
              </label>
              <select
                name="lawFirmSize"
                id="lawFirmSize"
                value={formData.lawFirmSize}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              >
                <option value="0">Select law firm size</option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-100">51-100</option>
                <option value="101-500">101-500</option>
                <option value="501-1000">501-1000</option>
                <option value="1000+">1000+</option>
              </select>

              {formErrors.lawFirmSize && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.lawFirmSize}</p>
              )}
            </div>
            <div className="box flex flex-col">
              <label htmlFor="lawFirmName" className="rounded-full py-2 pl-3 text-lg">
                Law Firm Name
              </label>
              <input
                placeholder="Law Firm Name"
                type="text"
                name="lawFirmName"
                id="lawFirmName"
                value={formData.lawFirmName}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg"
              />
              {formErrors.lawFirmName && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.lawFirmName}</p>
              )}
            </div>
          </div>

          {/* Fourth row */}
          <div className="row flex justify-center items-center">
            <div className="box flex flex-col w-[19%]">
              <label htmlFor="phoneNumber" className="rounded-full py-2 pl-3 text-lg">
                Code
              </label>
              <select
                name="countryCode"
                id="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                className="rounded-l-full py-2 pl-3 text-lg"
              >
                <option value="">Select Country Code</option>
                <option value="+91">+91 (IN)</option>
                <option value="+44">+44 (UK)</option>
                <option value="+1">+1 (US)</option>
                <option value="+93">+93 (AF)</option>
                <option value="+355">+355 (AL)</option>
                <option value="+213">+213 (DZ)</option>
                <option value="+1684">+1684 (AS)</option>
                <option value="+376">+376 (AD)</option>
                <option value="+244">+244 (AO)</option>
                <option value="+1264">+1264 (AI)</option>
                <option value="+672">+672 (AQ)</option>
                <option value="+1268">+1268 (AG)</option>
                <option value="+54">+54 (AR)</option>
                <option value="+374">+374 (AM)</option>
                <option value="+297">+297 (AW)</option>
                <option value="+61">+61 (AU)</option>
                <option value="+43">+43 (AT)</option>
                <option value="+994">+994 (AZ)</option>
                <option value="+1242">+1242 (BS)</option>
                <option value="+973">+973 (BH)</option>
                <option value="+880">+880 (BD)</option>
                <option value="+1246">+1246 (BB)</option>
                <option value="+375">+375 (BY)</option>
                <option value="+32">+32 (BE)</option>
                <option value="+501">+501 (BZ)</option>
                <option value="+229">+229 (BJ)</option>
                <option value="+1441">+1441 (BM)</option>
                <option value="+975">+975 (BT)</option>
                <option value="+591">+591 (BO)</option>
                <option value="+387">+387 (BA)</option>
                <option value="+267">+267 (BW)</option>
                <option value="+55">+55 (BR)</option>
                <option value="+246">+246 (IO)</option>
                <option value="+1284">+1284 (VG)</option>
                <option value="+673">+673 (BN)</option>
                <option value="+359">+359 (BG)</option>
                <option value="+226">+226 (BF)</option>
                <option value="+257">+257 (BI)</option>
                <option value="+855">+855 (KH)</option>
                <option value="+237">+237 (CM)</option>
                <option value="+1">+1 (CA)</option>
                <option value="+238">+238 (CV)</option>
                <option value="+1345">+1345 (KY)</option>
                <option value="+236">+236 (CF)</option>
                <option value="+235">+235 (TD)</option>
                <option value="+56">+56 (CL)</option>
                <option value="+86">+86 (CN)</option>
                <option value="+61">+61 (CX)</option>
                <option value="+61">+61 (CC)</option>
                <option value="+57">+57 (CO)</option>
                <option value="+269">+269 (KM)</option>
                <option value="+682">+682 (CK)</option>
                <option value="+506">+506 (CR)</option>
                <option value="+385">+385 (HR)</option>
                <option value="+53">+53 (CU)</option>
                <option value="+599">+599 (CW)</option>
                <option value="+357">+357 (CY)</option>
                <option value="+420">+420 (CZ)</option>
                <option value="+243">+243 (CD)</option>
                <option value="+45">+45 (DK)</option>
                <option value="+253">+253 (DJ)</option>
                <option value="+1767">+1767 (DM)</option>
                <option value="+1809">+1809 (DO)</option>
                <option value="+593">+593 (EC)</option>
                <option value="+20">+20 (EG)</option>
                <option value="+503">+503 (SV)</option>
                <option value="+240">+240 (GQ)</option>
                <option value="+291">+291 (ER)</option>
                <option value="+372">+372 (EE)</option>
                <option value="+251">+251 (ET)</option>
                <option value="+500">+500 (FK)</option>
                <option value="+298">+298 (FO)</option>
                <option value="+679">+679 (FJ)</option>
                <option value="+358">+358 (FI)</option>
                <option value="+33">+33 (FR)</option>
                <option value="+689">+689 (PF)</option>
                <option value="+241">+241 (GA)</option>
                <option value="+220">+220 (GM)</option>
                <option value="+995">+995 (GE)</option>
                <option value="+49">+49 (DE)</option>
                <option value="+233">+233 (GH)</option>
                <option value="+350">+350 (GI)</option>
                <option value="+30">+30 (GR)</option>
                <option value="+299">+299 (GL)</option>
                <option value="+1473">+1473 (GD)</option>
                <option value="+1671">+1671 (GU)</option>
                <option value="+502">+502 (GT)</option>
                <option value="+441481">+441481 (GG)</option>
                <option value="+224">+224 (GN)</option>
                <option value="+245">+245 (GW)</option>
                <option value="+592">+592 (GY)</option>
                <option value="+509">+509 (HT)</option>
                <option value="+504">+504 (HN)</option>
                <option value="+852">+852 (HK)</option>
                <option value="+36">+36 (HU)</option>
                <option value="+354">+354 (IS)</option>
                <option value="+62">+62 (ID)</option>
                <option value="+98">+98 (IR)</option>
                <option value="+964">+964 (IQ)</option>
                <option value="+353">+353 (IE)</option>
                <option value="+441624">+441624 (IM)</option>
                <option value="+972">+972 (IL)</option>
                <option value="+39">+39 (IT)</option>
                <option value="+225">+225 (CI)</option>
                <option value="+1876">+1876 (JM)</option>
                <option value="+81">+81 (JP)</option>
                <option value="+441534">+441534 (JE)</option>
                <option value="+962">+962 (JO)</option>
                <option value="+7">+7 (KZ)</option>
                <option value="+254">+254 (KE)</option>
                <option value="+686">+686 (KI)</option>
                <option value="+383">+383 (XK)</option>
                <option value="+965">+965 (KW)</option>
                <option value="+996">+996 (KG)</option>
                <option value="+856">+856 (LA)</option>
                <option value="+371">+371 (LV)</option>
                <option value="+961">+961 (LB)</option>
                <option value="+266">+266 (LS)</option>
                <option value="+231">+231 (LR)</option>
                <option value="+218">+218 (LY)</option>
                <option value="+423">+423 (LI)</option>
                <option value="+370">+370 (LT)</option>
                <option value="+352">+352 (LU)</option>
                <option value="+853">+853 (MO)</option>
                <option value="+389">+389 (MK)</option>
                <option value="+261">+261 (MG)</option>
                <option value="+265">+265 (MW)</option>
                <option value="+60">+60 (MY)</option>
                <option value="+960">+960 (MV)</option>
                <option value="+223">+223 (ML)</option>
                <option value="+356">+356 (MT)</option>
                <option value="+692">+692 (MH)</option>
                <option value="+222">+222 (MR)</option>
                <option value="+230">+230 (MU)</option>
                <option value="+262">+262 (YT)</option>
                <option value="+52">+52 (MX)</option>
                <option value="+691">+691 (FM)</option>
                <option value="+373">+373 (MD)</option>
                <option value="+377">+377 (MC)</option>
                <option value="+976">+976 (MN)</option>
                <option value="+382">+382 (ME)</option>
                <option value="+1664">+1664 (MS)</option>
                <option value="+212">+212 (MA)</option>
                <option value="+258">+258 (MZ)</option>
                <option value="+95">+95 (MM)</option>
                <option value="+264">+264 (NA)</option>
                <option value="+674">+674 (NR)</option>
                <option value="+977">+977 (NP)</option>
                <option value="+31">+31 (NL)</option>
                <option value="+599">+599 (AN)</option>
                <option value="+687">+687 (NC)</option>
                <option value="+64">+64 (NZ)</option>
                <option value="+505">+505 (NI)</option>
                <option value="+227">+227 (NE)</option>
                <option value="+234">+234 (NG)</option>
                <option value="+683">+683 (NU)</option>
                <option value="+850">+850 (KP)</option>
                <option value="+1670">+1670 (MP)</option>
                <option value="+47">+47 (NO)</option>
                <option value="+968">+968 (OM)</option>
                <option value="+92">+92 (PK)</option>
                <option value="+680">+680 (PW)</option>
                <option value="+970">+970 (PS)</option>
                <option value="+507">+507 (PA)</option>
                <option value="+675">+675 (PG)</option>
                <option value="+595">+595 (PY)</option>
                <option value="+51">+51 (PE)</option>
                <option value="+63">+63 (PH)</option>
                <option value="+64">+64 (PN)</option>
                <option value="+48">+48 (PL)</option>
                <option value="+351">+351 (PT)</option>
                <option value="+1787">+1787 (PR)</option>
                <option value="+974">+974 (QA)</option>
                <option value="+242">+242 (CG)</option>
                <option value="+262">+262 (RE)</option>
                <option value="+40">+40 (RO)</option>
                <option value="+7">+7 (RU)</option>
                <option value="+250">+250 (RW)</option>
                <option value="+590">+590 (BL)</option>
                <option value="+290">+290 (SH)</option>
                <option value="+1869">+1869 (KN)</option>
                <option value="+1758">+1758 (LC)</option>
                <option value="+590">+590 (MF)</option>
                <option value="+508">+508 (PM)</option>
                <option value="+1784">+1784 (VC)</option>
                <option value="+685">+685 (WS)</option>
                <option value="+378">+378 (SM)</option>
                <option value="+239">+239 (ST)</option>
                <option value="+966">+966 (SA)</option>
                <option value="+221">+221 (SN)</option>
                <option value="+381">+381 (RS)</option>
                <option value="+248">+248 (SC)</option>
                <option value="+232">+232 (SL)</option>
                <option value="+65">+65 (SG)</option>
                <option value="+1721">+1721 (SX)</option>
                <option value="+421">+421 (SK)</option>
                <option value="+386">+386 (SI)</option>
                <option value="+677">+677 (SB)</option>
                <option value="+252">+252 (SO)</option>
                <option value="+27">+27 (ZA)</option>
                <option value="+82">+82 (KR)</option>
                <option value="+211">+211 (SS)</option>
                <option value="+34">+34 (ES)</option>
                <option value="+94">+94 (LK)</option>
                <option value="+249">+249 (SD)</option>
                <option value="+597">+597 (SR)</option>
                <option value="+47">+47 (SJ)</option>
                <option value="+268">+268 (SZ)</option>
                <option value="+46">+46 (SE)</option>
                <option value="+41">+41 (CH)</option>
                <option value="+963">+963 (SY)</option>
                <option value="+886">+886 (TW)</option>
                <option value="+992">+992 (TJ)</option>
                <option value="+255">+255 (TZ)</option>
                <option value="+66">+66 (TH)</option>
                <option value="+228">+228 (TG)</option>
                <option value="+690">+690 (TK)</option>
                <option value="+676">+676 (TO)</option>
                <option value="+1868">+1868 (TT)</option>
                <option value="+216">+216 (TN)</option>
                <option value="+90">+90 (TR)</option>
                <option value="+993">+993 (TM)</option>
                <option value="+1649">+1649 (TC)</option>
                <option value="+688">+688 (TV)</option>
                <option value="+256">+256 (UG)</option>
                <option value="+380">+380 (UA)</option>
                <option value="+971">+971 (AE)</option>
                <option value="+598">+598 (UY)</option>
                <option value="+1340">+1340 (VI)</option>
                <option value="+998">+998 (UZ)</option>
                <option value="+678">+678 (VU)</option>
                <option value="+379">+379 (VA)</option>
                <option value="+58">+58 (VE)</option>
                <option value="+84">+84 (VN)</option>
                <option value="+681">+681 (WF)</option>
                <option value="+967">+967 (YE)</option>
                <option value="+260">+260 (ZM)</option>
                <option value="+263">+263 (ZW)</option>
                {/* Add more country codes as needed */}
              </select>
              {formErrors.countryCode && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.countryCode}</p>
              )}
            </div>
            <div className="box flex flex-col relative">
              <label htmlFor="phoneNumber" className="rounded-full py-2 pl-3 text-lg rounded-l-none">
                Phone Number
              </label>
              <input
                placeholder="Phone Number"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="rounded-full py-2 pl-3 text-lg rounded-l-none"
              />
              {formErrors.phoneNumber && (
                <p className="text-red-500 rounded-full py-2 pl-3 text-lg absolute">{formErrors.phoneNumber}</p>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="text-white font-semibold bg-blue-700 rounded-full py-2 w-[45%] mx-auto text-lg mt-4 mb-4"
          >
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
