import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser, updateEmailVerified } from "../../../redux/userSlice.js";
import DigitalClock from "./DigitalClock.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

const ClauseAiHeader = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [originalOtp, setOriginalOtp] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (user.planExpiry) {
      const expiryDate = new Date(user.planExpiry);
      const currentDate = new Date();
      if (currentDate > expiryDate) {
        setIsPlanExpired(true);
      }
    }
  }, [user.planExpiry]);

  const handleSendOtp = () => {
    setIsLoading(true);
    fetch("https://auth.clauseai.in/send-email-verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailId: user.emailId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setOriginalOtp(data.otp);
        setOtpModalOpen(true);
        setOtpMessage("OTP sent to your email.");
        setIsOtpSent(true);
        setShowVerificationMessage(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
        setOtpMessage("Error sending OTP. Please try again.");
        setIsLoading(false);
      });
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigateTo("/login");
  };

  const handleUpgrade = () => {
    window.open("https://clauseai.in/payment", "_blank");
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = () => {
    setIsLoading(true);
    fetch("https://auth.clauseai.in/verify-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp, originalOtp, email: user.emailId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOtpMessage("Email verified successfully!");
          setOtpError("");
          setIsOtpVerified(true);
          dispatch(updateEmailVerified(true));
          setTimeout(() => {
            setOtpModalOpen(false);
            setIsOtpVerified(false);
          }, 1500);
        } else {
          setOtpError(data.message || "Invalid OTP. Please try again.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        setOtpError("There was an error verifying the OTP. Please try again.");
        setIsLoading(false);
      });
  };

  return (
    <div className="w-[100%] py-5 px-8 bg-blue-700 text-white flex justify-between items-center text-3xl">
      <span>Clause Ai</span>
      <div>
        {user.planType === "Free" && !isPlanExpired && (
          <div className="text-xl flex items-center">
            <span className="mr-2">Trial Ends in :</span>
            <DigitalClock expiryDate={user.planExpiry} />
          </div>
        )}
      </div>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <button className="p-2">
          <FontAwesomeIcon icon={faUser} />
        </button>
        {isHovered && (
          <div className="user-details-box text-sm absolute right-0 mt-2 p-4 w-64 bg-blue-100 shadow-lg rounded-lg border border-gray-200 transition-opacity duration-200 opacity-100">
            <p className="text-gray-900 font-semibold flex flex-row justify-between">
              <span>Account : {user.username}</span>
              <button
                className={`text-white px-2 rounded-full text-sm ${
                  user.planType === "Free" ? "bg-blue-700" : "bg-modern-gold"
                }`}
              >
                {user.planType}
              </button>
            </p>
            {user.isLoggedIn ? (
              <>
                <p className="text-gray-600">{user.emailId}</p>
                {user.planType === "Free" && (
                  <p className="text-gray-600 mb-2 mt-2">
                    <button className="bg-blue-700 text-white px-2 py-1 rounded-full" onClick={handleUpgrade}>
                      Upgrade
                    </button>
                  </p>
                )}
                <p>
                  <button className="bg-blue-700 text-white px-2 py-1 rounded-full" onClick={handleLogout}>
                    Logout
                  </button>
                </p>
              </>
            ) : (
              navigateTo("/login")
            )}
          </div>
        )}
      </div>
      {isPlanExpired && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center font-sans ">
          <div className="bg-white p-8 rounded-xl shadow-lg !text-black m-8">
            <h2 className="text-4xl font-bold  mb-4">Your Plan has expired</h2>
            <p className="mb-4">Upgrade your plan to continue using the application.</p>
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-full font-medium text-base"
              onClick={handleUpgrade}
            >
              Upgrade
            </button>
          </div>
        </div>
      )}
      {!user.isEmailVerified && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-xl shadow-lg text-black m-8">
            <h2 className="text-4xl font-bold mb-4">Verify Your Email</h2>
            {showVerificationMessage && (
              <>
                <p className="mb-4">Please verify your email to continue using the application.</p>
                <button
                  className="bg-blue-700 text-white px-4 py-2 rounded-full font-medium text-base mb-4"
                  onClick={handleSendOtp}
                  disabled={isLoading}
                >
                  {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : "Send OTP"}
                </button>
              </>
            )}
            {otpMessage && <p className="mb-4">{otpMessage}</p>}
            {isOtpSent && (
              <>
                <input
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength="6"
                  className="border p-2 mb-4 w-full"
                  placeholder="Enter OTP"
                  disabled={isLoading}
                />
                <button
                  className="bg-blue-700 text-white px-4 py-2 rounded-full font-medium text-base"
                  onClick={handleOtpSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : "Verify OTP"}
                </button>
                {otpError && <p className="text-red-500 mt-2">{otpError}</p>}
                <button
                  className="text-blue-700 underline mt-4"
                  onClick={handleSendOtp}
                  disabled={isLoading}
                >
                  Resend OTP
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};


export default ClauseAiHeader;