import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import WelcomePage from "./WelcomePage";
import "../../index.css";
import LoginPage from "./LoginPage";
import ClauseGenerator from "./ClauseGenerator";
import SignupPage from "./SignupPage";
import { Provider } from "react-redux";
import store from "../../redux/store";
import GoogleSignInRegisterPage from "./GoogleSignInRegisterPage";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/generate_clause" element={<ClauseGenerator />} />
          <Route path="/google-auth-popup" element={<ClauseGenerator />} />
          <Route path="/gmail_register_form" element={<GoogleSignInRegisterPage />} />
          <Route path="/*" element={<WelcomePage />} />
          <Route path="*" element={<WelcomePage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
