// src/redux/slices/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  emailId: "",
  lawFirmName: "",
  lawFirmSize: "",
  phoneNumber: "",
  countryCode: "",
  country: "",
  planType: "",
  planExpiry: null, // Add planExpiry field
  isLoggedIn: false,
  isEmailVerified: false, // Add isEmailVerified field
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.username = action.payload.username;
      state.emailId = action.payload.emailId;
      state.lawFirmName = action.payload.lawFirmName;
      state.lawFirmSize = action.payload.lawFirmSize;
      state.phoneNumber = action.payload.phoneNumber;
      state.countryCode = action.payload.countryCode;
      state.country = action.payload.country;
      state.planType = action.payload.plan; // Default is Free plan
      state.planExpiry = action.payload.plan_expiry; // Set planExpiry
      state.isLoggedIn = true;
      state.isEmailVerified = action.payload.is_email_verified; // Set isEmailVerified
    },
    logoutUser: (state) => {
      state.username = "";
      state.emailId = "";
      state.lawFirmName = "";
      state.lawFirmSize = "";
      state.phoneNumber = "";
      state.countryCode = "";
      state.country = "";
      state.planType = "";
      state.planExpiry = null; // Clear planExpiry
      state.isLoggedIn = false;
      state.isEmailVerified = false; // Clear isEmailVerified
    },
    updateEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload;
    },
  },
});

export const { addUser, logoutUser,updateEmailVerified } = userSlice.actions;
export default userSlice.reducer;