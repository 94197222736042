import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { addUser } from "../../redux/userSlice.js.js";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import WelcomeHeader from "./Mini Components/WelcomeHeader.jsx";
import ForgotPasswordModal from "./ForgotPasswordModal.jsx";
import { ClimbingBoxLoader, ClockLoader, PropagateLoader } from "react-spinners";

const LoginPage = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // State for form data and errors
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Basic input validation
    let errors = {};
    let hasErrors = false;

    if (formData.username.trim() === "") {
      errors.username = "Username is required";
      hasErrors = true;
    }

    if (formData.password.trim() === "") {
      errors.password = "Password is required";
      hasErrors = true;
    }

    if (hasErrors) {
      setFormErrors(errors);
      setIsLoading(false);
    } else {
      // Clear any previous errors
      setFormErrors({
        username: "",
        password: "",
      });

      // Here you would typically send the form data to your backend
      try {
        // Example of sending form data to backend
        const response = await fetch("https://auth.clauseai.in/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("Login failed");
        }
        // Extract user object from response
        const responseData = await response.json();
        const user = responseData.user;
        // Dispatch addUser action to update Redux store
        dispatch(addUser(user));
        // Redirect user to another page after successful login
        setTimeout(() => {
          navigateTo("/generate_clause");
          setIsLoading(false);
        }, 1500);
        navigateTo("/generate_clause");
      } catch (error) {
        console.error("Login error:", error);
        // Handle login error (e.g., display error message to user)
        setIsLoading(false);
        setFormErrors({ username: 'Invalid credentials', password: 'Invalid credentials' });
      }
    }
  };

  // Function to handle Google login success
  const handleGoogleLoginSuccess = async (response) => {
    const decodedToken = jwtDecode(response.credential);
    setIsLoading(true);
    const user = {
      username: decodedToken.name,
      emailId: decodedToken.email,
      planType: "Free",
    };

    try {
      const loginResponse = await fetch("https://auth.clauseai.in/gmail_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: user.username, emailId: user.emailId }),
      });

      const loginData = await loginResponse.json();

      if (loginResponse.ok) {
        const user = loginData.user;
        // If user exists, dispatch addUser and navigate to generate_clause
        dispatch(addUser(user));
        setIsLoading(false);  

        navigateTo("/generate_clause");
      } else if (loginResponse.status === 404) {
        // If user does not exist, navigate to gmail_register_form
        dispatch(addUser(user));
        setIsLoading(false); 
        navigateTo("/gmail_register_form");
      } else {
        throw new Error(loginData.error || "Login failed");
      }
    } catch (error) {
      console.error("Error logging in with Google:", error.message);
      setIsLoading(false); 
      // Handle error appropriately, e.g., show an error message to the user
    }
  };

  // Function to handle Google login failure
  const handleGoogleLoginFailure = (error) => {
    // Handle Google login failure
    console.error("Google login failed", error);
    setIsLoading(false); 
  };

  

  // useEffect to adjust styles on mount (you can remove this if not needed)
  React.useEffect(() => {
    const signupBtnDiv = document.getElementById("signupbtn").querySelector("div");
    if (signupBtnDiv) {
      signupBtnDiv.style.margin = "auto";
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="113098793288-riadj86fu67p7kcj5oe48i0b02edtjhq.apps.googleusercontent.com">
      <div className="w-[100vw] h-[100vh] bg-blue-100 flex flex-col justify-evenly items-center">
        <div className="head w-[100%]">
          <WelcomeHeader />
        </div>
        <div className="login-box flex flex-col w-[100%] mx-auto text-xl">
          <h1 className="text-center font-semibold w-full mx-auto">Login</h1>
          {isLoading && <p className="w-full mx-auto text-center flex justify-center items-center my-4"><PropagateLoader color="#1976d2" /></p>}
        </div>
        <div className="form-container w-[100%]">
          <form action="" method="post" className="flex flex-col justify-between" onSubmit={handleSubmit}>
            <label htmlFor="username" className="rounded-full py-3 pl-4 w-[80%] mx-auto text-lg">
              Username
            </label>
            <input
              placeholder="Username"
              type="text"
              name="username"
              id="username"
              value={formData.username}
              onChange={handleChange}
              className="rounded-full py-3 pl-4 w-[80%] mx-auto text-lg"
            />
            {formErrors.username && (
              <p className="text-red-500 rounded-full py-3 pl-4 w-[80%] mx-auto text-lg">{formErrors.username}</p>
            )}

            <label htmlFor="password" className="rounded-full py-3 pl-4 w-[80%] mx-auto text-lg">
              Password
            </label>
            <input
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="rounded-full py-3 pl-4 w-[80%] mx-auto text-lg"
            />
            {formErrors.password && (
              <p className="text-red-500 rounded-full py-3 pl-4 w-[80%] mx-auto text-lg">{formErrors.password}</p>
            )}
            <span
              className="text-end w-[84%] mt-4 mb-4 font-semibold text-md cursor-pointer"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Forgot Password ?
            </span>
            <button className="text-white font-semibold bg-blue-700 rounded-full py-3 w-[80%] mx-auto text-xl mt-2 mb-2">
              Login
            </button>
          </form>
        </div>
        <div className="btn-box flex flex-row items-center justify-center w-[80%] gap-1 mx-auto text-lg mt-2 mb-2">
          <div className="line w-[20%] h-[2px] bg-black"></div>
          <span>Or continue with</span>
          <div className="line w-[20%] h-[2px] bg-black"></div>
        </div>
        <div className="btn-box flex flex-col w-[80%] mx-auto text-xl mt-2 mb-2" id="signupbtn">
          <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={handleGoogleLoginFailure} className="mx-auto" />
        </div>
        <ForgotPasswordModal isOpen={showForgotPasswordModal} onClose={() => setShowForgotPasswordModal(false)} />
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;