// import { JSDOM } from "jsdom";

export function GPTparseStringToObjectsWithRating(htmlString) {
    // Use DOMParser to parse the HTML string into a document object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Select relevant elements within the parsed DOM
    const elements = doc.querySelectorAll("h1, h2, p");

    // Initialize variables
    const result = [];
    let currentObj = {};

    // Iterate through selected elements
    elements.forEach((element) => {
      if (element.tagName === "H1") {
        // If encountering an H1, push currentObj to result (if not empty)
        if (Object.keys(currentObj).length > 0) {
          result.push(currentObj);
          currentObj = {};
        }
        // Set the title property of currentObj
        currentObj.title = element.textContent.trim();
      } else if (element.tagName === "H2") {
        // Set the rating property of currentObj
        currentObj.rating = element.textContent.trim();
      } else if (element.tagName === "P") {
        // Set the desc property of currentObj
        currentObj.desc = element.textContent.trim();
      }
    });

    // Push the last currentObj to result (if not empty)
    if (Object.keys(currentObj).length > 0) {
      result.push(currentObj);
    }

    return result;
  }


function parseStringToObjects(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const elements = doc.querySelectorAll("h1, p");
  const result = [];
  let currentObj = {};

  elements.forEach((element) => {
    if (element.tagName === "H1") {
      if (Object.keys(currentObj).length > 0) {
        result.push(currentObj);
        currentObj = {};
      }
      currentObj.title = element.textContent.trim();
    } else if (element.tagName === "P") {
      currentObj.desc = element.textContent.trim();
    }
  });

  if (Object.keys(currentObj).length > 0) {
    result.push(currentObj);
  }

  return result;
}

export function convertArrayToString(arr) {
  return arr.join("\n");
}


export function parseStringToObjectsWithRating(htmlString) {
  // Create a temporary div element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Select relevant elements within the parsed DOM
  const elements = tempDiv.querySelectorAll("h1, h2, p");

  // Initialize variables
  const result = [];
  let currentObj = {};

  // Iterate through selected elements
  elements.forEach((element) => {
    if (element.tagName === "H1") {
      // If encountering an H1, push currentObj to result (if not empty)
      if (Object.keys(currentObj).length > 0) {
        result.push(currentObj);
        currentObj = {};
      }
      // Set the title property of currentObj
      currentObj.title = element.textContent.trim();
    } else if (element.tagName === "H2") {
      // Set the rating property of currentObj
      currentObj.rating = element.textContent.trim();
    } else if (element.tagName === "P") {
      // Set the desc property of currentObj
      currentObj.desc = element.textContent.trim();
    }
  });

  // Push the last currentObj to result (if not empty)
  if (Object.keys(currentObj).length > 0) {
    result.push(currentObj);
  }
  return result;
}


// export function parseStringToObjectsWithRating(htmlString) {
//   const dom = new JSDOM(htmlString);
//   const doc = dom.window.document;
//   const elements = doc.querySelectorAll("h1, h2, p");
//   const result = [];
//   let currentObj = {};

//   elements.forEach((element) => {
//     if (element.tagName === "H1") {
//       if (Object.keys(currentObj).length > 0) {
//         result.push(currentObj);
//         currentObj = {};
//       }
//       currentObj.title = element.textContent.trim();
//     } else if (element.tagName === "H2") {
//       currentObj.rating = element.textContent.trim();
//     } else if (element.tagName === "P") {
//       currentObj.desc = element.textContent.trim();
//     }
//   });

//   if (Object.keys(currentObj).length > 0) {
//     result.push(currentObj);
//   }

//   return result;
// }

export const getClausesByText = async (content) => {
  const url = "https://api.openai.com/v1/chat/completions";
  const payload = {
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `You are an AI-based paralegal assistant. Generate all possible detailed and comprehensive sample clauses.Generate several detailed and descriptive sample clauses.`,
      },
      {
        role: "user",
        content: `Generate several detailed and comprehensive sample clauses that are based on the ${content}
        Give the clauses in such a manner that their titles are in <h1> tags and thier descriptions are in <p> tags separated by '\n \n' and dont give numbers in title of clause (Strict Instruction : give the clauses in the explained manner inside the tags only.)`,
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("HTTP error!");
    }

    const data = await response.json();
    const content = data.choices[0].message.content;
    console.log(data);
    let finalObjects = parseStringToObjects(content);
    return finalObjects;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getClausesByForm = async (formData) => {
  const { typeOfContract, customContract, clause, specificLaw, specificJurisdiction } = formData;
  const url = "https://api.openai.com/v1/chat/completions";
  const payload = {
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `You are an AI-based paralegal assistant. Generate all possible detailed and comprehensive sample clauses.Generate several detailed and descriptive sample clauses.`,
      },
      {
        role: "user",
        content: `Generate several detailed and comprehensive sample clauses that define the ${clause} within the context of a ${typeOfContract} or ${customContract}, specifically tailored for the jurisdiction of ${specificJurisdiction} or Indian jurisdiction. These clauses should ensure full compliance with ${specificLaw} laws. 
        Give the clauses in such a manner that their titles are in <h1> tags and thier descriptions are in <p> tags separated by '\n \n' and dont give numbers in title of clause (Strict Instruction : give the clauses in the explained manner inside the tags only.)`,
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("HTTP error!");
    }

    const data = await response.json();
    const content = data.choices[0].message.content;
    let finalObjects = parseStringToObjects(content);
    return finalObjects;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getSampleClauses = async (clause) => {
  const { content } = clause;
  const url = "https://api.openai.com/v1/chat/completions";
  const payload = {
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `You are an AI-based paralegal assistant. Generate all possible detailed and comprehensive sub clauses.Generate several detailed and descriptive sub clauses.`,
      },
      {
        role: "user",
        content: ` 
        ${content}. 
        Now create the different version of this clause by repharsing and modifying the different words and terms create 5 like these 
        Give the clauses in such a manner that their titles are in <h1> tags and thier descriptions are in <p> tags separated by '\n \n' (Strict Instruction : give the clauses in the explained manner inside the tags only.)`,
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("HTTP error!");
    }

    const data = await response.json();
    const content = data.choices[0].message.content;
    console.log(content);
    let finalObjects = parseStringToObjects(content);
    console.log(finalObjects);
    return finalObjects;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const rateSelectedClause = async (clause) => {
  const url = "https://api.openai.com/v1/chat/completions";
  const payload = {
    model: "gpt-4-turbo",
    messages: [
      {
        role: "system",
        content: `You are an AI-based paralegal assistant. Generate all possible detailed and comprehensive sample clauses that are asked to generate from you.`,
      },
      {
        role: "user",
        content: `Analyse the given clause ${clause} and keep this clause one of the category Low Risk,Medium Risk and High Risk and modify this clause and give some modified clause with highly recommendation to least recommendation
        Give the clauses in such a manner that their category are in <h1> tags and thier descriptions are in <p> tags separated by '\n \n' and dont give numbers in category of clause give category name (Strict Instruction : give the clauses in the explained manner inside the tags only.)`,
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("HTTP error!");
    }

    const data = await response.json();
    const content = data.choices[0].message.content;
    console.log(data);
    let finalObjects = parseStringToObjectsWithRating(content);
    return finalObjects;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const apiKey = "sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5";
