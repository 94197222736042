export const gptURL = "https://api.openai.com/v1/chat/completions";
export const API_KEY = "sk-proj-kxGEiTAhBN2dHa6GgCIiT3BlbkFJTc0SbkMWFKgt3MlRxNk5";
export const ASSISTANT_ID = "asst_B4D91rjNzAnbiFs1FMDnJuXd";
export const typeOfContracts = [
  "Employment Agreement",
  "Sales Agreement",
  "Lease Agreement",
  "Non-Disclosure Agreement (NDA)",
  "Partnership Agreement",
  "Service Agreement",
  "Consulting Agreement",
  "Licensing Agreement",
  "Franchise Agreement",
  "Loan Agreement",
  "Independent Contractor Agreement",
  "Distribution Agreement",
  "Joint Venture Agreement",
  "Construction Contract",
  "Settlement Agreement",
  "Affiliate Agreement",
  "Warranty Agreement",
  "Real Estate Purchase Agreement",
  "Shareholder Agreement",
  "Agency Agreement",
  "Manufacturing Agreement",
  "Advertising Agreement",
  "Maintenance Agreement",
  "Sponsorship Agreement",
  "Confidentiality Agreement",
  "Settlement and Release Agreement",
  "Brokerage Agreement",
  "Artist Agreement",
  "Event Planning Agreement",
  "Collaboration Agreement",
  "Settlement Agreement",
  "Freelance Agreement",
  "Publishing Agreement",
  "Subscription Agreement",
  "Stock Purchase Agreement",
  "Operating Agreement",
  "Data Sharing Agreement",
  "Management Agreement",
  "Technology Transfer Agreement",
  "Grant Agreement",
  "Memorandum of Understanding (MOU)",
  "Settlement Agreement",
  "Supply Agreement",
];

export const typeOfClauses = [
  "Term of Agreement",
  "Scope of Work",
  "Payment Terms",
  "Confidentiality",
  "Intellectual Property Rights",
  "Warranties and Representations",
  "Indemnification",
  "Limitation of Liability",
  "Dispute Resolution",
  "Termination",
  "Force Majeure",
  "Governing Law",
  "Assignment",
  "Notices",
  "Entire Agreement",
  "Amendments",
  "Severability",
  "Waiver",
  "Counterparts",
  "Third-Party Beneficiaries",
  "Insurance",
  "Compliance with Laws",
  "Subcontracting",
  "Change Orders",
  "Time is of the Essence",
  "Arbitration",
  "Jurisdiction",
  "Survival",
  "Non-Compete",
  "Non-Solicitation",
  "Data Protection",
  "Publicity",
  "Inspection and Acceptance",
  "Delivery Terms",
  "Risk of Loss",
  "Return of Property",
  "Governing Language",
  "Independent Contractor",
  "Audit Rights",
  "Performance Bond",
  "Retention of Title",
  "Environmental Compliance",
  "Employee Non-Disclosure",
  "Default",
  "Remedies",
  "Liquidated Damages",
  "No Partnership",
  "Right to Injunction",
  "Due Diligence",
  "Transition Assistance",
  "Disclaimers",
  "Compliance with Export Laws",
  "Non-Disparagement",
  "Exclusivity",
  "Escrow",
  "Order of Precedence",
  "Expense Reimbursement",
  "Background Checks",
  "Security Requirements",
  "Product Recalls",
  "Social Responsibility",
  "Covenants",
  "Set-Off",
  "Mitigation of Damages",
  "Force Majeure Notification",
  "Performance Metrics",
  "Milestones",
  "Penalties",
  "Right of First Refusal",
  "Most Favored Nation",
  "Benchmarking",
  "Injunction Relief",
  "Disengagement Assistance",
  "Intellectual Property Assignment",
  "Use of Name and Logo",
  "Cooperation",
  "Health and Safety",
  "Supplier Diversity",
  "Corporate Social Responsibility",
  "Data Security",
  "Product Specifications",
  "Testing and Acceptance",
  "Use of Subcontractors",
  "Staffing Requirements",
  "Training",
  "Documentation",
  "Reporting Requirements",
  "Change Control",
  "Business Continuity",
  "Disaster Recovery",
  "Workplace Policies",
  "Conflict of Interest",
  "Ethical Conduct",
  "Human Rights",
  "Accessibility",
  "Advertising",
  "Language of Agreement",
  "Employee Training",
  "Quality Assurance",
  "Return Policy",
  "Intellectual Property Indemnity",
  "Technical Support",
  "Usage Reports",
  "Termination for Convenience",
  "Termination for Cause",
  "Breach Notification",
  "Product Liability",
  "Consumer Rights",
  "Fraud Prevention",
  "Governing Body",
  "Operational Requirements",
  "Maintenance Services",
  "Software Licensing",
  "Hardware Requirements",
  "Supply Chain Management",
  "Investment Commitment",
  "Marketing Cooperation",
  "Promotional Activities",
  "Strategic Alliance",
  "Technology Transfer",
  "Trade Secrets",
  "Trial Period",
  "Workplace Safety",
  "Continuous Improvement",
  "Consultation",
  "Crisis Management",
  "Termination Assistance",
  "Exit Strategy",
  "Future Enhancements",
  "System Integration",
  "Compatibility",
  "Service Levels",
  "Performance Reviews",
  "Annual Review",
  "Customer Feedback",
  "Market Research",
  "Industry Standards",
  "Product Development",
  "Project Management",
  "Key Personnel",
  "Stakeholder Engagement",
  "Legal Compliance",
  "Regulatory Requirements",
  "Litigation Management",
  "Dispute Escalation",
  "Grievance Mechanism",
  "Public Relations",
  "Media Communication",
  "Intellectual Property Enforcement",
  "Trademark Use",
  "Patent Rights",
  "Copyright Infringement",
  "Open Source Software",
  "API Access",
  "Database Rights",
  "Licensing Fees",
  "Revenue Sharing",
  "Profit Distribution",
  "Financial Statements",
  "Taxation",
  "Audit Compliance",
  "Financial Reporting",
  "Capital Expenditure",
  "Operational Expenses",
  "Budget Approval",
  "Cost Control",
  "Efficiency Targets",
  "Revenue Targets",
  "Sales Quotas",
  "Market Penetration",
  "Expansion Plans",
  "Restructuring",
  "Downsizing",
  "Outsourcing",
  "Relocation",
  "Resource Allocation",
  "Staffing Levels",
  "Human Resources Management",
  "Compensation and Benefits",
  "Performance Incentives",
  "Work-Life Balance",
  "Employee Retention",
  "Talent Acquisition",
  "Skill Development",
  "Mentorship Programs",
  "Succession Planning",
  "Labor Relations",
  "Union Negotiations",
  "Collective Bargaining",
  "Employment Contracts",
  "Non-Compete Agreements",
  "Non-Solicitation Agreements",
  "Employee Grievances",
  "Workplace Harassment",
  "Equal Opportunity",
  "Diversity and Inclusion",
  "Code of Conduct",
  "Professional Standards",
];
