import React from "react";

const GenerateClauseButton = () => {
  return (
    <button className="rounded-xl py-2 mt-6  bg-blue-700 text-white w-[88%] text-center text-base">
      Generate Clauses
    </button>
  );
};

export default GenerateClauseButton;
