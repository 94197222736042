import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import dotIcon from "../../../../assets/dotIcon.png";
import CustomModal from "./Modal";
import MoreClausesModal from "./MoreClausesModal";
import insertText from "../../office-document";

const SampleClauseCard = ({ title, content }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sampleModalIsOpen, setSampleModalIsOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    insertText(content);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openSampleModal = () => {
    event.preventDefault();
    setSampleModalIsOpen(true);
  };

  const closeSampleModal = () => {
    setSampleModalIsOpen(false);
  };

  return (
    <div className="clause-card flex flex-row justify-between h-[10rem] bg-white w-[92%] mx-auto rounded-2xl p-4 gap-6">
      <div className="body flex flex-col gap-2">
        <div className="card-head text-xl font-semibold">{title}</div>
        <span className="text-base overflow-hidden">{content}</span>
      </div>
      <div className="btns-container w-[20%] py-4 flex flex-col justify-between items-center">
        <button className="p-1 rounded-lg border-2 border-black" onClick={handleClick}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <img
          src={dotIcon}
          alt="dotIcon"
          height={"18px"}
          width={"18px"}
          onClick={openModal}
          className="cursor-pointer"
        />
      </div>
      <CustomModal title={title} content={content} isOpen={modalIsOpen} closeModal={closeModal} />
    </div>
  );
};

export default SampleClauseCard;
