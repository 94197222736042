import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlus } from "@fortawesome/free-solid-svg-icons";
import insertText, { replaceSelectedText } from "../../office-document";
// Styling for the modal (optional)
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#ebf8ff",
    opacity: 0, // Start with opacity 0 for animation
    transition: "opacity 0.5s ease", // Transition for opacity
  },
};

const CustomModal = ({ title, content, isOpen, closeModal }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false); // New state for copied status

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        setIsCopied(true); // Set copied status to true when text is copied
        setTimeout(() => setIsCopied(false), 3000); // Reset after 3 seconds
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsVisible(true);
      }, 100); // Delay before showing modal content
    } else {
      setIsVisible(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          opacity: isVisible ? 1 : 0, // Change opacity based on visibility state
        },
      }}
      contentLabel="Example Modal"
    >
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">{title}</h2>
        <button onClick={closeModal} className="border-none bg-none cursor-pointer text-2xl font-bold">
          &times;
        </button>
      </div>
      <div>
        <p className="">{content}</p>
      </div>
      <div className="flex justify-end mt-4 gap-2">
        <button className="p-1 rounded-lg border-2 border-black" onClick={() => replaceSelectedText(content)}>
          Replace with selected text
        </button>
        <button
          className="flex items-center px-4 py-2 rounded border border-gray-300 cursor-pointer"
          onClick={() => insertText(content)}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Clause
        </button>
        <button
          onClick={() => copyToClipboard(content)}
          className="px-2 rounded-lg py-1 borderborder-gray-300 cursor-pointer"
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>
      {isCopied && (
        <p className="mt-2 text-green-500">Copied to clipboard!</p> // Message that is displayed when text is copied
      )}
    </Modal>
  );
};

export default CustomModal;
