import React from "react";
import "../../../index.css"; // Import the shimmer effect CSS
const ShimmerCards = () => {
  return (
    <div className="clause-card flex flex-row justify-between h-[10rem] bg-white w-[92%] mx-auto rounded-2xl p-4 gap-6 shimmer-wrapper">
      <div className="body flex flex-col gap-2 shimmer-card"></div>
    </div>
  );
};

export default ShimmerCards;
