import React from "react";
import LoginButton from "./Mini Components/LoginButton";
import WelcomeHeader from "./Mini Components/WelcomeHeader";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const navigateTo = useNavigate();
  return (
    <div className="w-[100vw] h-[100vh] bg-blue-100 flex flex-col justify-center items-center gap-16">
      <WelcomeHeader />
      <div className="login-box flex flex-col w-[80%] mx-auto text-xl">
        <span className="font-semibold mb-2">Already registered  with Clause Ai ?</span>
        <LoginButton />
      </div>
      <div className="signup-box flex flex-col w-[80%] mx-auto text-xl">
        <span className="font-semibold mb-2">Don&apos;t have an account ?</span>
        <button
          className="text-white font-semibold bg-blue-700 rounded-full py-3 text-xl"
          onClick={() => navigateTo("/signup")}
        >
          Signup
        </button>
      </div>
      <div className="help-box flex flex-col w-[80%] mx-auto text-xl">
        <span className="font-semibold mb-2">Need help ? Contact</span>
        <span className="font-semibold mb-2">sclauseai@gmail.com</span>
      </div>
    </div>
  );
};

export default WelcomePage;
